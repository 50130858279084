import { Button } from 'react-bootstrap';
import { ReactComponent as ImageCaretRight } from '../../images/caret-right-fill.svg';


const SubNavigationTeaserButton = () => {

    return (
        <Button
            href="/Anmeldung"
            variant="rbs-button-orange info-teaser"
            size="lg"
        >
            <ImageCaretRight 
                className="me-1 SubNavigationTeaserButton" 
                width={16} 
                height={16} 
                fill='#fff' 
            />
            FPT 2025: <strong>04. / 05.02.</strong>
        </Button>
    )

};

export default SubNavigationTeaserButton;
