import React, { Fragment, useState } from 'react';
import { connect, useSelector } from 'react-redux'

import { Badge, Button } from 'react-bootstrap';
import { readAndAddVortrag } from '../../store/actions/fpt';
import ImageGreenCircle from '../../images/green-circle.png';


const FptProgrammItem = (props) => {

    //useSelector(state => console.log("state", state));

    const vortraege = useSelector(state => state.fpt.vortraege);
    const [showDetails, setShowDetails] = useState(undefined);

    //console.log("#########################", props.data);

    const openDetailVortrag = (id) => {
        setShowDetails(id);
        // Wenn der Vortrag noch nicht im Store ist, laden wir ihn aus der DB
        const v = vortraege.find(a => a.id === id);
        !v && props.dispatch(readAndAddVortrag(id));
    }

    const displayTag = (tagin) => {
        const tag = tagin.trim();

        const mappings = {
            netzwerk: {
                //style: { backgroundColor: '#555', color: '#fff' },
                label: 'Netzwerk',
                bg: "BadgeNetzwerk"
            },
            process: {
                //style: { backgroundColor: '#3080ee', color: '#fff' },
                label: 'Prozessautomation',
                bg: "BadgeProzessautomation"
            },
            sicher: {
                //style: { backgroundColor: '#fed052', },
                label: 'Sicherheit',
                bg: "BadgeSicherheit"
            },
            green: {
                //style: { backgroundColor: 'green', color: '#fff' },
                label: 'Green',
                bg: "BadgeGreen"
            },
            robotik: {
                label: 'Robotik',
                bg: "BadgeRobotik"
            },
            control: {
                //style: { backgroundColor: 'rgb(255 24 0)', color: '#fff' },
                label: 'Steuerung',
                bg: "BadgeSteuerung"
            },
            ki: {
                //style: { backgroundColor: 'rgb(255 146 0)', color: '#fff' },
                label: 'KI in Automation',
                bg: "BadgeKI"
            },
            maschinenbau: {
                //style: { backgroundColor: 'rgb(255 146 0)', color: '#fff' },
                label: 'Machinenbau',
                bg: "BadgeKI"
            },
            sensor: {
                //style: { backgroundColor: '#49B77B', color: '#fff' },
                label: 'Sensorik',
                bg: "BadgeSensorik"
            },
            mess: {
                //style: { backgroundColor: '#49B77B', color: '#fff' },
                label: 'Messtechnik',
                bg: "BadgeSensorik"
            },
            digital: {
                label: 'Digitalisierung',
                bg: "BadgeDigital"
            },
            IoT: {
                label: 'IoT',
                bg: "BadgeIoT"
            },
        }

        if (tag === 'green') {
            return (
                <img key={tag} src={ImageGreenCircle} height="30" alt="Green Automation" className='ImageGreenCircle' />
            )
        }

        return (
            <>
                {mappings[tag] &&
                    <Badge key={tag} bg={mappings[tag].bg}>
                        {mappings[tag].label}
                    </Badge>
                }
                <br />
            </>
        )
    }

    const toggleDetail = (id) => {
        if (showDetails === id) {
            setShowDetails(undefined);
        } else {
            openDetailVortrag(id);
        }
    }

    return (
        <Fragment key={props.key}>
            {props.data.type === 'kontaktmesse' &&
                < tr className='ProgrammGreyRow' >
                    <td className="ProgrammTime">
                        {props.data.zeit}
                    </td>
                    <td className='ProgrammEvent'>
                        <h1
                            dangerouslySetInnerHTML={{ __html: props.data.content }}
                        />
                    </td>
                    <td className='ProgrammType d-none d-lg-block'>
                        <br />
                    </td>
                </tr >
            }

            {(props.data.type === 'vortrag') &&
                <tr>
                    <td className="ProgrammTime">
                        {props.data.zeit}
                    </td>
                    <td className='ProgrammEvent'>
                        <a
                            onClick={() => toggleDetail(props.data.id)}
                            className={(showDetails === props.data.id) ? 'ProgrammToggler active' : 'ProgrammToggler'}
                        >
                            <span className='ProgrammCompany'>{props.data.firma}</span>
                            {props.data.id === 2044 &&
                                <span className='ProgrammCompany'>ARNOLD UMFORMTECHNIK GmbH & Co. KG</span>
                            }
                            <span className='ProgrammSpeaker'>
                                {props.data.referent}
                                {props.data.referent2 && ', ' + props.data.referent2}
                            </span>
                            <h1 className='ProgrammHeadline'>{props.data.content}</h1>
                        </a>
                        {showDetails === props.data.id &&
                            <div className='ProgrammContent'>
                                <p
                                    dangerouslySetInnerHTML={{ __html: vortraege && vortraege.find(v => v.id === props.data.id)?.abstract }}
                                />
                                <Button variant="btn btn-rbs-button-orange" href={'https://portal.fachpresse.live/'+ props.idFpt + '/thema/' + props.data.id} target="_blank">Zum Download</Button>
                            </div>
                        }
                    </td>
                    <td className='ProgrammType d-none d-lg-block'>
                        {props.data.tags && props.data.tags.split(',').map(tag =>
                            <React.Fragment key={tag}>
                                {displayTag(tag)}
                            </React.Fragment>
                        )}
                        <br />
                    </td>
                </tr>
            }
            {(props.data.type === 'meetthepress') &&
                <tr>
                    <td className="ProgrammTime">
                        {props.data.zeit}
                    </td>
                    <td className='ProgrammEvent'>
                        <h1 className='ProgrammHeadline' dangerouslySetInnerHTML={{ __html: props.data.content}}></h1>
                    </td>                 
                </tr>
            }



        </Fragment >

    )

}

export default connect()(FptProgrammItem);
